import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { GYGIA_TAGS } from '~/lib/shopify-tags'

import { useBodyScrollLock } from '@unlikelystudio/react-hooks'

import { CloseIcon } from '~/components/Abstracts/Icons'
import Video from '~/components/Slices/Video'
import FormEvent, { FormEventProps } from '~/components/UI/FormEvent'
import GlBackground from '~/components/UI/GlBackground'
import Image, { imageLoaderPrismic, ImageProps } from '~/components/UI/Image'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'
import TextHorizontalScrolling from '~/components/UI/TextHorizontalScrolling'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'
import { useMathFitStyle } from '~/hooks/useMathFit'
import {
  setCookiesAttempts,
  useIsAllowedToShowNewsletter,
} from '~/hooks/useNewsletterPopIn'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface HeroMainTeasingProps {
  className?: string
  repeatSmallText?: string
  repeatBigText?: string
  image?: ImageProps
  video?: string
  videoRatio?: number
  links?: InlineCtaProps[]
  formTitle?: FormEventProps['title']
  formText?: FormEventProps['text']
  formCheckboxLabel?: FormEventProps['checkboxLabel']
  formLegals?: FormEventProps['legals']
  formTag?: FormEventProps['tag']
  campaign?: FormEventProps['campaign']
}

function HeroMainTeasing({
  className,
  image,
  video,
  videoRatio,
  repeatSmallText,
  repeatBigText,
  links,
  formTitle,
  formText,
  formCheckboxLabel,
  formLegals,
  campaign,
}: HeroMainTeasingProps) {
  // Getting the slice position to set the correct priority
  const { position: slicePosition } = useSliceProvider()

  const [hidePoster, setHidePoster] = useState(false)

  const {
    targetRef: videoRef,
    parentRef: componentRef,
    processedCss,
  } = useMathFitStyle({ type: 'cover' }, [])

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const bottomRef = useRef(null)
  const [enablePanel, setEnablePanel] = useState(false)
  const [canHavePanel, setCanHavePanel] = useState(true)
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })
  const isAllowed = useIsAllowedToShowNewsletter()
  const locale = useLocale()

  useEffect(() => {
    if (inView && isAllowed) setEnablePanel(true)
  }, [inView])

  const showPanel = canHavePanel && enablePanel
  useBodyScrollLock(showPanel)

  const formProps: FormEventProps = useMemo(
    () => ({
      title: formTitle,
      text: formText,
      checkboxLabel: formCheckboxLabel,
      legals: formLegals,
      setCanHavePanel: setCanHavePanel,
      onSuccess: () => {
        setCookiesAttempts(locale)
      },
      tag: GYGIA_TAGS.WEBSITE_POPUP,
      campaign,
    }),
    [
      formTitle,
      formText,
      formCheckboxLabel,
      formLegals,
      setCanHavePanel,
      locale,
      campaign,
    ],
  )

  return (
    <section ref={componentRef} className={cx(css.HeroMainTeasing, className)}>
      <div
        className={css.topContainer}
        onClick={() => {
          setEnablePanel(true)
          setCanHavePanel(true)
        }}>
        {video && (
          <div
            className={css.containerVideo}
            ref={videoRef}
            style={processedCss}>
            <Video
              videoPlayerClassName={css.videoPlayer}
              mockClassName={css.videoMock}
              src={video}
              imagePoster={image}
              controls={false}
              hasInView={false}
              onHidePoster={setHidePoster}
              videoRatio={videoRatio}
              disableControls
              isAutoplay
              hasPlaysInline
              isMuted
              loop
              disableGrid
              disableMock
            />
          </div>
        )}

        {!video && image && (
          <>
            <Image
              priority={slicePosition ? slicePosition === 1 : false}
              className={cx(css.image, { hide: hidePoster })}
              layout="fill"
              objectFit="cover"
              {...image}
            />
            <GlBackground
              className={css.glBackground}
              imageWidth={image.width}
              imageHeight={image.height}
              src={imageLoaderPrismic({
                src: image.src,
                width: 3880,
                quality: 80,
              })}
              debug={false}
            />
          </>
        )}

        <div className={cx(css.links, gridStyle)}>
          <div className={css.linksContainer}>
            {links?.map((link, index) => (
              <InlineCta
                key={`inline_cta_${index}`}
                textPreset={GlobalTextPreset.Cta14HafferSemiBold}
                textStyling={GlobalTextStyling.UpperCase}
                theme={GlobalThemeColors.Black}
                lineProps={{ initialLineState: 'none' }}
                {...link}
              />
            ))}
          </div>
        </div>

        <div className={css.repeatTexts}>
          {repeatSmallText && (
            <TextHorizontalScrolling
              className={css.repeatText}
              itemClassName={css.repeatSmallItem}
              text={repeatSmallText}
              theme={GlobalThemeColors.Black}
              preset={GlobalTextPreset.Title14HafferSemiBold}
              speed={0.3}
            />
          )}

          {repeatBigText && (
            <TextHorizontalScrolling
              className={css.repeatText}
              text={repeatBigText}
              theme={GlobalThemeColors.Black}
              preset={GlobalTextPreset.Title24HafferSemiBold}
              speed={0.2}
            />
          )}
        </div>
      </div>

      <div className={css.marker} ref={inViewRef} />
      <AnimatePresence>
        {showPanel && (
          <m.div className={cx(css.bottomFormContainer)}>
            <m.div
              className={css.background}
              onClick={() => {
                setCookiesAttempts(locale)
                setCanHavePanel(false)
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: 'linear' }}
            />
            <m.div
              className={cx(css.bottomForm, gridStyle)}
              ref={bottomRef}
              initial={{ opacity: 0, translateY: '20%' }}
              animate={{
                opacity: 1,
                translateY: '0%',
                transition: { duration: 0.3, ease: 'easeOut' },
              }}
              exit={{
                opacity: 0,
                translateY: '20%',
                transition: {
                  duration: 0.3,
                  ease: 'easeIn',
                },
              }}>
              <FormEvent {...formProps} />

              <CloseIcon
                theme={'black'}
                className={cx(css.closeIcon)}
                onClick={() => {
                  setCookiesAttempts(locale)
                  setCanHavePanel(false)
                }}
              />
            </m.div>
          </m.div>
        )}
      </AnimatePresence>
    </section>
  )
}

HeroMainTeasing.defaultProps = {}

export default HeroMainTeasing
