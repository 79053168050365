import { useEffect, useState } from 'react'

export default function usePixelRatio() {
  const [pixelRatio, setPixelRatio] = useState(1)

  useEffect(() => {
    setPixelRatio(window.devicePixelRatio)
  }, [])

  return pixelRatio
}
