import classnames from 'classnames/bind'
import { useSpring, useTime } from 'framer-motion'
import fit from 'math-fit'
import React, { useCallback, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMeasure } from 'react-use'

import {
  clamp,
  useIsomorphicLayoutEffect,
  useLatestCallback,
} from '@unlikelystudio/react-hooks'

import usePixelRatio from '~/hooks/usePixelRatio'

import {
  createProgramFromSources,
  resizeCanvasToDisplaySize,
} from '~/utils/web-gl'

import fragment from './shader.frag'
import vertex from './shader.vert'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GlBackgroundProps {
  className?: string
  src: string
  imageWidth: number
  imageHeight: number
  debug?: boolean
}

export interface GlData {
  gl: WebGLRenderingContext
  program: WebGLProgram
}

export interface Locations {
  mousePositionLocation: WebGLUniformLocation
  timeLocation: WebGLUniformLocation
  resolutionLocation: WebGLUniformLocation
  screenSizeLocation: WebGLUniformLocation
  transformLocation: WebGLUniformLocation
  bgSizeLocation: WebGLUniformLocation
  speedLocation: WebGLUniformLocation
  offsetLocation: WebGLUniformLocation
  amplitudeLocation: WebGLUniformLocation
  strenghtLocation: WebGLUniformLocation
  scaleLocation: WebGLUniformLocation
  positionLocation: number
  texCoordLocation: number
}

const distortionParams = {
  strength: -1.48,
  speed: 1,
  offset: 3.0,
  scale: 1.39,
  amplitude: 0.1,
}

function setRectangle(
  gl: WebGLRenderingContext,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  const x1 = x
  const x2 = x + width
  const y1 = y
  const y2 = y + height
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]),
    gl.STATIC_DRAW,
  )
}
function GlBackground({
  className,
  src,
  imageWidth,
  imageHeight,
  debug = false,
}: GlBackgroundProps) {
  const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const canvasRef = useRef<HTMLCanvasElement>()
  const ratio = usePixelRatio()
  const clampedRatio = Math.min(ratio, 2)
  const glData = useRef<GlData>({
    gl: null,
    program: null,
  })

  const { ref: inViewRef, inView } = useInView()

  const texture = useRef<WebGLTexture>()

  const locations = useRef<Locations>({
    mousePositionLocation: null,
    timeLocation: null,
    positionLocation: null,
    amplitudeLocation: null,
    texCoordLocation: null,
    resolutionLocation: null,
    screenSizeLocation: null,
    transformLocation: null,
    scaleLocation: null,
    speedLocation: null,
    offsetLocation: null,
    strenghtLocation: null,
    bgSizeLocation: null,
  })

  const image = useRef<HTMLImageElement>()

  const [ref, { width, height, top, left }] = useMeasure<HTMLCanvasElement>()

  const setRef = useCallback(
    (node: HTMLCanvasElement) => {
      canvasRef.current = node
      ref(node)
      inViewRef(node)
    },
    [ref, inViewRef],
  )

  const [transitionOption, setTransitionOption] = useState({
    stiffness: 200,
    damping: 50,
    restDelta: 0.001,
    restSpeed: 0.001,
  })

  /*
    Note from Clément 09/03/2023 :
    I'm commenting this for now to avoid loading the `debugPane` function
    Because this function loads the `tweakpane` lib which has a really huge size in the package
    You can uncomment this whole part if you need to tweak the GL again
  */
  // // Create dev panels to tweak uniforms  in real tim
  // useIsomorphicLayoutEffect(() => {
  //   if (debug) {
  //     const { dispose } = debugPane({
  //       locations,
  //       glData,
  //       distortionParams,
  //       transitionOption,
  //       setTransitionOption,
  //     })

  //     return () => {
  //       dispose()
  //     }
  //   }
  // }, [])

  const lerpedMousePositionX = useSpring(0, transitionOption)
  const lerpedMousePositionY = useSpring(0, transitionOption)

  // Update Shader with the lerped mouse position
  useIsomorphicLayoutEffect(() => {
    lerpedMousePositionX.onChange(() => {
      const mousePositionLocation = locations.current.mousePositionLocation
      glData.current.gl.uniform2f(
        mousePositionLocation,
        lerpedMousePositionX.get(),
        lerpedMousePositionY.get(),
      )
    })
    lerpedMousePositionY.onChange(() => {
      const mousePositionLocation = locations.current.mousePositionLocation
      glData.current.gl.uniform2f(
        mousePositionLocation,
        lerpedMousePositionX.get(),
        lerpedMousePositionY.get(),
      )
    })
  }, [])

  const render = useLatestCallback(() => {
    // Tell WebGL how to convert from clip space to pixels

    // Create a buffer to put three 2d clip space points in
    const positionBuffer = glData.current.gl.createBuffer()

    // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
    glData.current.gl.bindBuffer(glData.current.gl.ARRAY_BUFFER, positionBuffer)
    // Set a rectangle the same size as the image.
    setRectangle(glData.current.gl, 0, 0, imageWidth, imageHeight)

    // provide texture coordinates for the rectangle.
    const texcoordBuffer = glData.current.gl.createBuffer()
    glData.current.gl.bindBuffer(glData.current.gl.ARRAY_BUFFER, texcoordBuffer)
    glData.current.gl.bufferData(
      glData.current.gl.ARRAY_BUFFER,
      new Float32Array([
        0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
      ]),
      glData.current.gl.STATIC_DRAW,
    )

    glData.current.gl.viewport(
      0,
      0,
      width * clampedRatio,
      height * clampedRatio,
    )
    resizeCanvasToDisplaySize(canvasRef.current, clampedRatio)

    // Tell it to use our program (pair of shaders)
    glData.current.gl.useProgram(glData.current.program)

    // Turn on the position attribute
    glData.current.gl.enableVertexAttribArray(
      locations.current.positionLocation,
    )

    // Bind the position buffer.
    glData.current.gl.bindBuffer(glData.current.gl.ARRAY_BUFFER, positionBuffer)

    // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    let size = 2 // 2 components per iteration
    let type = glData.current.gl.FLOAT // the data is 32bit floats
    let normalize = false // don't normalize the data
    let stride = 0 // 0 = move forward size * sizeof(type) each iteration to get the next position
    let offset = 0 // start at the beginning of the buffer
    glData.current.gl.vertexAttribPointer(
      locations.current.positionLocation,
      size,
      type,
      normalize,
      stride,
      offset,
    )

    // Turn on the texcoord attribute
    glData.current.gl.enableVertexAttribArray(
      locations.current.texCoordLocation,
    )

    // bind the texcoord buffer.
    glData.current.gl.bindBuffer(glData.current.gl.ARRAY_BUFFER, texcoordBuffer)

    // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
    size = 2 // 2 components per iteration
    type = glData.current.gl.FLOAT // the data is 32bit floats
    normalize = false // don't normalize the data
    stride = 0 // 0 = move forward size * sizeof(type) each iteration to get the next position
    offset = 0 // start at the beginning of the buffer
    glData.current.gl.vertexAttribPointer(
      locations.current.texCoordLocation,
      size,
      type,
      normalize,
      stride,
      offset,
    )

    // Draw the rectangle.
    const primitiveType = glData.current.gl.TRIANGLES
    const count = 6
    offset = 0
    glData.current.gl.drawArrays(primitiveType, offset, count)
  })

  const initScene = useLatestCallback(() => {
    const gl = canvasRef.current.getContext('webgl')
    const program = createProgramFromSources(gl, [vertex, fragment])
    glData.current = {
      gl,
      program,
    }

    locations.current = {
      mousePositionLocation: gl.getUniformLocation(
        glData.current.program,
        'u_mousePosition',
      ),
      timeLocation: gl.getUniformLocation(glData.current.program, 'u_time'),
      scaleLocation: gl.getUniformLocation(glData.current.program, 'u_scale'),
      strenghtLocation: gl.getUniformLocation(
        glData.current.program,
        'u_strength',
      ),
      speedLocation: gl.getUniformLocation(glData.current.program, 'u_speed'),
      offsetLocation: gl.getUniformLocation(glData.current.program, 'u_offset'),
      positionLocation: gl.getAttribLocation(
        glData.current.program,
        'a_position',
      ),
      texCoordLocation: gl.getAttribLocation(
        glData.current.program,
        'a_texCoord',
      ),
      resolutionLocation: gl.getUniformLocation(
        glData.current.program,
        'u_resolution',
      ),
      screenSizeLocation: gl.getUniformLocation(
        glData.current.program,
        'u_sreenSize',
      ),
      transformLocation: gl.getUniformLocation(
        glData.current.program,
        'u_transform',
      ),
      bgSizeLocation: gl.getUniformLocation(glData.current.program, 'u_bgSize'),
      amplitudeLocation: gl.getUniformLocation(
        glData.current.program,
        'u_amplitude',
      ),
    }
  })

  // Init webgl scene
  useIsomorphicLayoutEffect(() => {
    initScene()
    render()

    // Init uniforms with default values
    glData.current.gl.uniform1f(
      locations.current.speedLocation,
      distortionParams.speed,
    )
    glData.current.gl.uniform1f(
      locations.current.strenghtLocation,
      distortionParams.strength,
    )
    glData.current.gl.uniform1f(
      locations.current.offsetLocation,
      distortionParams.offset,
    )
    glData.current.gl.uniform1f(
      locations.current.scaleLocation,
      distortionParams.scale,
    )
    glData.current.gl.uniform1f(
      locations.current.amplitudeLocation,
      distortionParams.amplitude,
    )
  }, [initScene, render])

  useIsomorphicLayoutEffect(() => {
    // set the resolution
    glData.current.gl.uniform2f(
      locations.current.resolutionLocation,
      width,
      height,
    )

    // set the screen size
    glData.current.gl.uniform2f(
      locations.current.screenSizeLocation,
      width,
      height,
    )

    // set the background size
    glData.current.gl.uniform2f(
      locations.current.bgSizeLocation,
      imageWidth,
      imageHeight,
    )

    // set the transform to apply to have a cover effect
    const cover = fit.cover(
      {
        w: imageWidth,
        h: imageHeight,
      },
      {
        w: width,
        h: height,
      },
    )

    glData.current.gl.uniform3f(
      locations.current.transformLocation,
      cover.left / width,
      cover.top / height,
      cover.scale,
    )
  }, [width, height, imageWidth, imageHeight])

  // Load image into a texture
  useIsomorphicLayoutEffect(() => {
    image.current = new Image(imageWidth, imageHeight)
    image.current.crossOrigin = 'anonymous'
    image.current.src = src
    image.current.onload = () => {
      const { gl } = glData.current
      texture.current = gl.createTexture()
      gl.bindTexture(gl.TEXTURE_2D, texture.current)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
      // Upload the image into the texture.
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        image.current,
      )
      setImageIsLoaded(true)
    }

    return () => {
      setImageIsLoaded(false)
      image.current.onload = null
    }
  }, [src, imageWidth, imageHeight])

  // On Mouse Move update cursor position
  useIsomorphicLayoutEffect(() => {
    function onMouseMove(e: MouseEvent) {
      const mouseX = clamp(((e.clientX - left) / width) * 2 - 1, -1, 1)
      const mouseY = clamp(((e.clientY - top) / height) * -2 + 1, -1, 1)

      lerpedMousePositionX.set(mouseX)
      lerpedMousePositionY.set(mouseY)
    }
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [top, left, width, height])

  // On Mouse Leave update cursor position
  useIsomorphicLayoutEffect(() => {
    function onMouseLeave() {
      lerpedMousePositionX.set(0)
      lerpedMousePositionY.set(0)
    }
    const canvas = canvasRef.current
    canvas?.addEventListener('mouseleave', onMouseLeave)
    return () => {
      canvas?.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [])

  //On device orientation move update cursor position
  // useIsomorphicLayoutEffect(() => {
  //   function onDeviceOrientation(e: DeviceOrientationEvent) {
  //     const mouseX = e.gamma / 90
  //     const mouseY = -e.beta / 90

  //     lerpedMousePositionX.set(mouseX)
  //     lerpedMousePositionY.set(mouseY)
  //   }
  //   window.addEventListener('deviceorientation', onDeviceOrientation, false)
  //   return () => {
  //     window.removeEventListener(
  //       'deviceorientation',
  //       onDeviceOrientation,
  //       false,
  //     )
  //   }
  // }, [])

  const time = useTime()
  useIsomorphicLayoutEffect(() => {
    if (inView && imageIsLoaded) {
      time.onChange((time) => {
        glData.current.gl.uniform1f(locations.current.timeLocation, time / 1000)
        render()
      })
    }

    return () => {
      time.destroy()
    }
  }, [inView, imageIsLoaded])

  return (
    <canvas
      ref={setRef}
      className={cx(css.GlBackground, className, { imageIsLoaded })}
    />
  )
}

GlBackground.defaultProps = {}

export default GlBackground
