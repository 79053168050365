import classnames from 'classnames/bind'
import React, { useCallback } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalPosition } from '~/@types/position'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText from '~/components/Abstracts/RichText'
import { ArticleCardProps } from '~/components/Slices/ArticleCard'
import Image from '~/components/UI/Image'
import Link from '~/components/UI/Link'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PushImageProps {
  className?: string
  leftItem?: ArticleCardProps
  rightItem?: ArticleCardProps
  reversed?: boolean
}

const IMAGE_SIZES = {
  BIG: [{ breakpoint: 'md', ratio: 11 / 24 }, { ratio: 22 / 24 }],
  SMALL: [{ breakpoint: 'md', ratio: 7 / 24 }, { ratio: 20 / 24 }],
}

interface ItemPushImage extends ArticleCardProps {
  position?: GlobalPosition.LEFT | GlobalPosition.RIGHT
  big?: boolean
}

function PushImage({
  className,
  leftItem,
  rightItem,
  reversed,
}: PushImageProps) {
  const { ref } = useTrackingPromotionImpression()
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24HafferSemiBold,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.Black,
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Text12_14Haffer,
    color: GlobalThemeColors.MineShaft,
  })
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const { position: slicePosition } = useSliceProvider()

  const renderItem = useCallback(
    ({ name, label, image, link, position, big }: ItemPushImage) => (
      <Link className={cx(css?.[position])} {...link}>
        <>
          {image && (
            <Image
              className={css.image}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              sizesFromBreakpoints={big ? IMAGE_SIZES.BIG : IMAGE_SIZES.SMALL}
              ratio={css.ratio}
              priority={slicePosition === 1}
              {...image}
            />
          )}
          <div className={css.texts}>
            {name && <h3 className={cx(nameStyle, css.name)}>{name}</h3>}
            {label && (
              <RichText className={cx(labelStyle, css.label)} render={label} />
            )}
          </div>
        </>
      </Link>
    ),
    [nameStyle, labelStyle],
  )

  return (
    <div
      ref={ref}
      className={cx(css.PushImage, gridStyle, className, { reversed })}>
      {leftItem &&
        renderItem({
          ...leftItem,
          position: GlobalPosition.LEFT,
          big: !reversed,
        })}
      {rightItem &&
        renderItem({
          ...rightItem,
          position: GlobalPosition.RIGHT,
          big: reversed,
        })}
    </div>
  )
}

PushImage.defaultProps = {}

export default PushImage
