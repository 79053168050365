export default `precision highp float;

// our texture
uniform sampler2D u_image;
uniform vec2 u_mousePosition;
uniform vec3 u_transform;
uniform vec2 u_resolution;
uniform vec2 u_bgSize;
uniform float u_time;
uniform float u_speed;
uniform float u_strength;
uniform float u_offset;
uniform float u_scale;
uniform float u_amplitude;


// the texCoords passed in from the vertex shader.
varying vec2 v_texCoord;
varying vec2 v_position;
varying vec2 v_clipSpacePosition;

//Random hash to generate noise
float hash(in float n) { return fract(cos(n)*43758.5453123); }

void main() {
   // Distortion algorithm is a modification https://www.shadertoy.com/view/4sXcDN
   //--------------------------Distorsion Algorithm Start----------------------------------------
   vec2 source_uv = v_texCoord;
   vec2 u_mousePosition_uv = u_mousePosition * vec2(-1., -1.) * vec2(-0.5, 0.5) + 0.5;
   //assume your distortion center coordinate is (0.5,0.5),you can use your distortion center instead.
   vec2 distortion_center = u_mousePosition_uv;
    //Define algorithm dependent variables
   float distortion_x,distortion_y,rr,r2,theta;
   //Define distortion coefficient K1 and K2 ,In most cases we can only adjust K1. then K2 parameters can be adjusted more perfect Effect
   //u_time is used for Real-time change.
   //K1 < 0 is pincushion distortion
   //K1 >=0 is barrel distortion
   float distortion_k1 = u_amplitude * sin(u_time*u_speed) - u_offset,distortion_k2 = u_strength * -1.;
   vec2 dest_uv;

   rr = sqrt((source_uv.x - distortion_center.x)*(source_uv.x - distortion_center.x) + (source_uv.y - distortion_center.y)*(source_uv.y - distortion_center.y));
   r2 = rr * (1.0 + distortion_k1*(rr*rr) + distortion_k2*(rr*rr*rr*rr));
   theta = atan(source_uv.x - distortion_center.x, source_uv.y - distortion_center.y);
   distortion_x = sin(theta) * r2 * u_scale;//1.0 is  scale factor
   distortion_y = cos(theta) * r2 * u_scale;//1.0 is  scale factor
   dest_uv.x = distortion_x + distortion_center.x;
   dest_uv.y = distortion_y + distortion_center.y;
   //--------------------------Algorithm End----------------------------------------

   // Repeat the image
   dest_uv = fract(dest_uv * 0.5 + 0.5);
   // Mirror the repeated image
   dest_uv = abs((dest_uv - 0.5) * 2.);

   vec4 texture = texture2D(u_image, dest_uv);
   // Add noise
   float noiseStrength = 0.12;
   texture += hash((hash(dest_uv.x) - dest_uv.y) * u_time) * noiseStrength;

   float darken = noiseStrength * 0.8;

   gl_FragColor = vec4(texture.r - darken, texture.g - darken, texture.b - darken, texture.a);
}`
