const defaultShaderType = ['VERTEX_SHADER', 'FRAGMENT_SHADER']

export function loadShader(
  gl: WebGLRenderingContext,
  shaderSource: string,
  shaderType: number,
  opt_errorCallback: (error: string) => void,
) {
  const errFn = opt_errorCallback || console.log
  // Create the shader object
  const shader = gl.createShader(shaderType)

  // Load the shader source
  gl.shaderSource(shader, shaderSource)

  // Compile the shader
  gl.compileShader(shader)

  // Check the compile status
  const compiled = gl.getShaderParameter(shader, gl.COMPILE_STATUS)
  if (!compiled) {
    // Something went wrong during compilation; get the error
    const lastError = gl.getShaderInfoLog(shader)
    errFn("*** Error compiling shader '" + shader + "':" + lastError)
    gl.deleteShader(shader)
    return null
  }

  return shader
}

export function createProgram(
  gl: WebGLRenderingContext,
  shaders: WebGLShader[],
  opt_attribs?: string[],
  opt_locations?: number[],
  opt_errorCallback?: (error: string) => void,
) {
  const errFn = opt_errorCallback || console.log
  const program = gl.createProgram()
  shaders.forEach(function (shader) {
    gl.attachShader(program, shader)
  })
  if (opt_attribs) {
    opt_attribs.forEach(function (attrib, ndx) {
      gl.bindAttribLocation(
        program,
        opt_locations ? opt_locations[ndx] : ndx,
        attrib,
      )
    })
  }
  gl.linkProgram(program)

  // Check the link status
  const linked = gl.getProgramParameter(program, gl.LINK_STATUS)
  if (!linked) {
    // something went wrong with the link
    const lastError = gl.getProgramInfoLog(program)
    errFn('Error in program linking:' + lastError)

    gl.deleteProgram(program)
    return null
  }
  return program
}

export function clearCanvas(gl: WebGLRenderingContext) {
  gl.clearColor(0, 0, 0, 0)
  gl.clear(gl.COLOR_BUFFER_BIT)
}

export function resizeCanvasToDisplaySize(
  canvas: HTMLCanvasElement,
  multiplier?: number,
) {
  multiplier = multiplier || 1
  const width = (canvas.clientWidth * multiplier) | 0
  const height = (canvas.clientHeight * multiplier) | 0
  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = Math.round(width)
    canvas.height = Math.round(height)
    return true
  }
  return false
}

export function createProgramFromSources(
  gl: WebGLRenderingContext,
  shaderSources: [string, string],
  opt_attribs?: string[],
  opt_locations?: number[],
  opt_errorCallback?: (error: string) => void,
) {
  const shaders: WebGLShader[] = []
  for (let ii = 0; ii < shaderSources.length; ++ii) {
    shaders.push(
      loadShader(
        gl,
        shaderSources[ii],
        gl[defaultShaderType[ii]],
        opt_errorCallback,
      ),
    )
  }
  return createProgram(
    gl,
    shaders,
    opt_attribs,
    opt_locations,
    opt_errorCallback,
  )
}
